import * as React from "react";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Apprentice",
    price: "30",
    description: [
      "Basic AI agents capable of handling routine, repetitive tasks.",
      "Delivers the output of a small team of human workers, saving over 20 human hours per AI hour.",
      "Includes unlimited tool usage and 5,000 tool calls per hour.",
      "Perfect for tasks like data entry, reporting, and simple workflows.",
      "Great for startups or businesses starting with automation.",
    ],
    buttonText: "Get Started",
    buttonVariant: "outlined",
    buttonColor: "primary",
    overageNote: "Additional hours: $30/hour.",
  },
  {
    title: "Skilled",
    subheader: "Most Popular",
    price: "50",
    description: [
      "Mid-level AI agents capable of handling more complex workflows.",
      "Delivers the output of a large human team, saving over 20 human hours per AI hour.",
      "Includes unlimited tool usage and 10,000 tool calls per hour.",
      "Ideal for handling advanced tasks like data analysis, multi-step processes, and workflow optimization.",
      "Perfect for growing businesses and teams scaling their operations.",
    ],
    buttonText: "Upgrade to Skilled",
    buttonVariant: "contained",
    buttonColor: "secondary",
    overageNote: "Additional hours: $50/hour.",
  },
  {
    title: "Master",
    price: "80",
    description: [
      "Elite AI agents capable of handling the most sophisticated tasks.",
      "Delivers the output of an expert human team, saving over 20 human hours per AI hour.",
      "Includes unlimited tool usage and 20,000 tool calls per hour.",
      "Designed for high-level tasks like strategic planning, advanced automation, and creative problem-solving.",
      "Ideal for professional services & high-growth teams looking for maximum productivity and precision.",
    ],
    buttonText: "Get Started",
    buttonVariant: "outlined",
    buttonColor: "primary",
    overageNote: "Additional hours: $80/hour.",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary", mb: 2 }}
        >
          Pricing
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.secondary", lineHeight: 1.7 }}
        >
          <strong>Transparent pricing</strong> designed for businesses of all
          sizes. With Fiscus, you gain access to <strong>autonomous AI teams</strong> that replace entire teams of human workers.
          <br />
          <br />
          Each AI agent brings exceptional productivity, saving over 
          <strong> 20 human hours per AI hour</strong>. Choose a plan based on
          the quality of work and the complexity of tasks you need to automate.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === "Master" ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                },
                tier.title === "Skilled" &&
                  ((theme) => ({
                    border: "none",
                    background:
                      "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles("dark", {
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    },
                    tier.title === "Skilled"
                      ? { color: "grey.100" }
                      : { color: "" },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Skilled" && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )}
                </Box>
                <Box
                  sx={[
                    {
                      display: "flex",
                      alignItems: "baseline",
                    },
                    tier.title === "Skilled"
                      ? { color: "grey.50" }
                      : { color: null },
                  ]}
                >
                  <Typography component="h3" variant="h2">
                    {`$${tier.price}`}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per hour
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Skilled"
                          ? { color: "green" }
                          : { color: "green" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Skilled"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as "outlined" | "contained"}
                  sx={{
                    backgroundColor:
                      tier.buttonVariant === "contained"
                        ? green[500]
                        : "transparent",
                    color:
                      tier.buttonVariant === "contained" ? "black" : green[500],
                    borderColor:
                      tier.buttonVariant === "outlined"
                        ? green[500]
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        tier.buttonVariant === "contained"
                          ? green[700]
                          : green[50],
                      color:
                        tier.buttonVariant === "contained"
                          ? "black"
                          : green[700],
                      borderColor:
                        tier.buttonVariant === "outlined"
                          ? green[700]
                          : "transparent",
                    },
                  }}
                  onClick={() => {
                    window.open(
                      "https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form",
                      "_blank"
                    );
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  textAlign: "center",
                  color: "text.secondary",
                  fontStyle: "italic",
                }}
              >
                {tier.overageNote}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
