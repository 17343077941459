import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import TeamImage1 from "../assets/team-create-1.png";
import TeamImage2 from "../assets/team-create-2.png";
import TeamImage3 from "../assets/team-create-3.png";
import TeamImage4 from "../assets/team-create-4.png";

const items = [
  {
    icon: <DashboardCustomizeRoundedIcon />, // Replacing ViewQuiltRoundedIcon
    title: "Command Center",
    description:
      "Effortlessly manage your AI workforce with a single, intuitive dashboard. Provide simple prompts, track tasks, and see real-time progress at a glance.",
    imageLight: TeamImage1,
    imageDark: TeamImage1,
  },
  {
    icon: <SyncRoundedIcon />, // Replacing EdgesensorHighRoundedIcon
    title: "Seamless Integrations",
    description:
      "Connect your AI agents to tools you already use—like Slack, QuickBooks, and Stripe. Extend your team’s reach without overhauling your systems.",
    imageLight: TeamImage2,
    imageDark: TeamImage2,
  },
  {
    icon: <GroupRoundedIcon />, // Replacing DevicesRoundedIcon
    title: "Collaborative AI Teams",
    description:
      "Deploy multi-agent workflows that work together like your team would—only faster, smarter, and with limitless scalability.",
    imageLight: TeamImage4,
    imageDark: TeamImage4,
  },
];

interface ChipProps {
  selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background: `linear-gradient(to bottom right, ${green[500]}, ${green[700]})`,
        color: "hsl(0, 0%, 20%)",
        borderColor: green[500],
        "&:hover": {
          background: `linear-gradient(to bottom right, ${green[400]}, ${green[600]})`, // Slightly lighter green on hover
          color: "hsl(0, 0%, 10%)", // Even darker text on hover
        },
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 20%)", // Label text remains consistent
        },
        ...theme.applyStyles("dark", {
          borderColor: green[700],
        }),
      },
    },
  ],
}));

interface MobileLayoutProps {
  selectedItemIndex: number;
  handleItemClick: (index: number) => void;
  selectedFeature: (typeof items)[0];
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        {/* 
          Changed the width/height to be responsive with maxWidth/width and aspectRatio.
          This keeps the images from stretching on mobile and maintains containment.
        */}
        <Box
          sx={(theme) => ({
            m: "auto",
            width: "100%",
            maxWidth: 420,
            aspectRatio: "420 / 500", // Keep the same shape as before
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
            ...theme.applyStyles("dark", {
              backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
            }),
          })}
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Product Features
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.secondary", mb: { xs: 2, sm: 4 } }}
        >
          Fiscus gives your team the tools they need to operate at their best.
          From centralized dashboards to seamless integrations and collaborative
          AI workflows, discover how our platform helps you simplify operations,
          save time, and drive meaningful results.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "action.selected",
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: 1,
                      textAlign: "left",
                      textTransform: "none",
                      color: "text.secondary",
                    },
                    selectedItemIndex === index && {
                      color: "text.primary",
                    },
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
            height: "var(--items-image-height)",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
            }}
          >
            {/* 
              Same responsive approach here: maxWidth/width and aspectRatio 
              to keep desktop and mobile images consistent and contained.
            */}
            <Box
              sx={(theme) => ({
                m: "auto",
                width: "100%",
                maxWidth: 420,
                aspectRatio: "420 / 500",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
                ...theme.applyStyles("dark", {
                  backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
                }),
              })}
            />
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
