import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// Import local assets
import GoogleLogo from '../assets/google-white.png';
import HubspotLogo from '../assets/hubspot-white.png';
import MicrosoftLogo from '../assets/microsoft-white.png';
import QuickBooksLogo from '../assets/quickbooks-white.png';
import SlackLogo from '../assets/slack-white.png';
import StripeLogo from '../assets/stripe-white.png';

const logoContainerStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '8px',
};

const logos = [
  MicrosoftLogo,
  GoogleLogo,
  QuickBooksLogo,
  HubspotLogo,
  SlackLogo,
  StripeLogo,
];

export default function LogoCollection() {
  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Connect your agents to the tools you already use
      </Typography>
      <Grid container sx={{ justifyContent: 'center', mt: 0.5, opacity: 0.6 }}>
        {logos.map((logo, index) => (
          <Grid item key={index} sx={logoContainerStyle}>
            <img
              src={logo}
              alt={`Company logo ${index + 1}`}
              style={
                {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                } as React.CSSProperties
              } // Explicitly cast as React.CSSProperties
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
